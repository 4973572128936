import { Link } from 'gatsby';
import React from 'react';

const Notes = () => {
  return (
    <>
      <h1>Rejestracja</h1>
      <Link to="/">Powrót</Link>
    </>
  );
};

export default Notes;
